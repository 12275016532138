import * as Sentry from '@sentry/browser';
import posthog from '@/helpers/posthog';
import _ from 'lodash';
import { jwtDecode } from 'jwt-decode';
import Intercom from '@/helpers/Intercom';

import {
  getAuth,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
  applyActionCode,
  confirmPasswordReset,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signOut,
  onAuthStateChanged,
  Auth as FirebaseAuth,
  multiFactor,
  TotpMultiFactorGenerator,
  TotpSecret,
  getMultiFactorResolver,
  MultiFactorError,
  User,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';
import { FirebaseApp as FirebaseAppType, FirebaseError } from 'firebase/app';

import api from './Api';
import FirebaseApp from '@/FirebaseApp';
import { isDefined } from '@/helpers/isDefined';

declare module 'firebase/auth' {
  export interface User {
    accessToken: string;
  }
}

export interface MfaSetup {
  secretKey: string;
  qrCodeUrl: string;
  finaliseEnrollment: (verifcationCode: string) => Promise<FinaliseEnrolmentResult>;
}
export type MfaSetupError = 'RequiresReauthentication';
export type FinaliseEnrolmentResult = 'Success' | 'InvalidVerificationCode';

export type MfaSignInResult = 'Success' | 'Timeout';

export type FinaliseSignIn = () => Promise<void>;
export type FinaliseMfaSignIn = (oneTimePassword: string) => Promise<MfaSignInResult>;

export enum AdditionalLoginSteps {
  OneTimePassword = 'OneTimePassword',
  None = 'None',
}

export class NoTokenError extends Error {
  constructor() {
    super('No token found');
    this.name = 'NoTokenError';
  }
}

export interface MfaRequiredSignInResult {
  additionalLoginSteps: AdditionalLoginSteps.OneTimePassword;
  finaliseSignIn: FinaliseMfaSignIn;
}

export interface NoAdditionalStepsSignInResult {
  additionalLoginSteps: AdditionalLoginSteps.None;
  finaliseSignIn: FinaliseSignIn;
}

type SignInResult = MfaRequiredSignInResult | NoAdditionalStepsSignInResult;

const isMultiFactorError = (error: unknown): error is MultiFactorError => {
  return (error as MultiFactorError).code !== undefined;
};

const isFirebaseError = (error: unknown): error is FirebaseError => {
  return (error as FirebaseError).code !== undefined;
};

class Auth {
  firebaseAuth: FirebaseAuth;
  idTokenRefreshedCallback: (() => void) | undefined;
  #isRefreshingMeData = false;

  get isRefreshingMeData() {
    return this.#isRefreshingMeData;
  }

  constructor(app: FirebaseAppType) {
    /**
     * See https://firebase.google.com/docs/auth/web/start#web-version-9
     */
    this.firebaseAuth = getAuth(app);

    onAuthStateChanged(this.firebaseAuth, async (user) => {
      if (user) {
        Sentry.setUser({ id: user.uid || undefined, email: user.email || undefined });
        // It is important that we have a working actingOrganizationId before trying to fetch 'me'
        // data. Some problems this prevents:
        //   1) if a user is removed from an organization then the server will start emitting
        //      'Unauthorized' whenever their 'feebris-acting-organization-id' header has a value
        //      for their old-org. The api/graphql will cause an auth.signout() because of this.
        //      Now, because the user has an invalid actingOrganizationId in their localStorage,
        //      they will be unable to login due to this.refreshMeData() instantly triggering
        //      an auth.signout() when the 'me' data fails with 'Unauthorized'.
        //   2) if the browser's localStorage gets somehow otherwise corrupted and the value
        //      for the actingOrganizationId is no longer valid
        await this.ensureValidActingOrganizationId(user.uid);
        // After login is fully completed, and on every full page load, refresh the 'me' data
        await this.refreshMeData();
      } else {
        // HACK: We have a race condition happening, we think it's due to AutomaticLogoutAlert
        //       triggering in a scenario where the user has closed their laptop for the night
        //       then opened it again in the morning. The end result is firebase is logged out
        //       but idToken is still present. In this case simply call signout again to ensure
        //       all localStorage state is cleared up.
        if (window.localStorage.getItem('idToken')) {
          this.signout();
        }
        Intercom.shutdown();
        posthog.capture('logout');
        posthog.reset();
      }
    });

    // A callback which will be connected to by the AutomaticLogoutAlert component
    this.idTokenRefreshedCallback = undefined;
  }

  async verifyPasswordResetCode(actionCode: string) {
    return verifyPasswordResetCode(this.firebaseAuth, actionCode);
  }

  async confirmPasswordReset(actionCode: string, password: string) {
    return confirmPasswordReset(this.firebaseAuth, actionCode, password);
  }

  async applyActionCode(actionCode: string) {
    return applyActionCode(this.firebaseAuth, actionCode);
  }

  async signoutUnauthorisedUser() {
    await this.signout(auth.isShareTokenUser() ? '/expiredShareToken' : '/login');
  }

  async isSignedOut() {
    return (
      !this.firebaseAuth.currentUser &&
      !window.localStorage.getItem('idToken') &&
      !this.isShareTokenUser()
    );
  }

  async signout(destination = '/login') {
    // When we get 401s, the JS code execution attempts to sign out in multiple places
    // We are simply going to ignore the signout if we are already signed out to avoid
    // weirdness with the navigation to the destination further down.
    if (await this.isSignedOut()) {
      return;
    }

    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'Signed out',
      level: 'info',
    });

    await this._signout();
    // Notice that this redirect does not immediately stop the rest of the JS code executing in the page
    // so we need to properly handle response.data being null because of 401's everywhere
    // https://stackoverflow.com/questions/36398482/why-does-setting-window-location-href-not-stop-script-execution
    window.location.href = destination;
  }

  async _signout() {
    // window.localStorage.removeItem will throw if the key does not exist, this is unhelpful
    for (const key of ['me', 'shareToken', 'hideAppBar', 'idToken', 'exitLoginAsToken']) {
      try {
        window.localStorage.removeItem(key);
      } catch {
        // ignore
      }
    }

    if (this.firebaseAuth.currentUser) {
      await signOut(this.firebaseAuth);
    }
  }

  async getAuthorizationHeader() {
    const shareToken = this.getShareToken();

    if (shareToken) {
      return `Share ${shareToken}`;
    }

    const idToken = await this.getIdToken();

    if (idToken) {
      return `Bearer ${idToken}`;
    }

    return undefined;
  }

  private getShareToken() {
    return window.localStorage.getItem('shareToken');
  }

  captureShareToken() {
    const urlParams = new URLSearchParams(window.location.search);

    const shareToken = urlParams.get('share');

    if (shareToken) {
      window.localStorage.setItem('shareToken', shareToken);

      // Also stash any accessory information, like if the app bar should be hidden (for iframe use)
      const hideAppBar = urlParams.get('hideAppBar');
      if (hideAppBar === 'true') {
        window.localStorage.setItem('hideAppBar', hideAppBar);
      } else {
        // Ensure we clean any previous values from localStorage
        try {
          window.localStorage.removeItem('hideAppBar');
        } catch {
          // Ignore
        }
      }
    }
  }

  /**
   * Returns the Firebase JWT for the currently logged in user.
   *
   * `currentUser.getIdToken()` will transparently handle caching and refreshing the token,
   * see https://stackoverflow.com/a/47805455, however on full page refresh firebase asynchronously
   * loads the currentUser which means that the this.firebaseAuth.currentUser will be null until
   * onAuthStateChanged is called! To prevent briefly seeing the login page until onAuthStateChanged
   * is triggered, we cached the idToken in local storage, see: https://stackoverflow.com/a/51334201
   *
   * Also, notice that the 'source of truth' for the token is firebase, not the 'idToken' in localStorage
   * This also means that if you modify/break the token manually using dev tools, it will be transparently
   * replaced with the right one by the code below.
   * Depending on how fast/slow the code executes, it is still possible that the code below kicks the user out before the token is refreshed
   * https://github.com/feebris/feebris/blob/97694511ff80d8a74ebee7ee8ff4d1a437a60f01/portal/src/controllers/Api.js#L67
   */
  private async getIdToken() {
    let idToken;
    if (this.firebaseAuth.currentUser) {
      idToken = await this.firebaseAuth.currentUser.getIdToken();
      if (this.idTokenRefreshedCallback) {
        this.idTokenRefreshedCallback();
      }
      // idToken may have updated, refresh our cached copy
      window.localStorage.setItem('idToken', idToken);
    } else {
      idToken = window.localStorage.getItem('idToken');
    }

    // If we don't have a token, we should throw an error to prevent the request from being made
    // This most likely means the user is no longer logged in
    if (!idToken) {
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'No token found',
        level: 'warning',
      });
      throw new NoTokenError();
    }

    return idToken;
  }

  /**
   * Returns the Firebase User ID for the currently logged in user.
   *
   * Falls back to extracting the User ID from the cached idToken, if possible.
   */
  getUid(): string | null {
    if (this.firebaseAuth.currentUser) {
      return this.firebaseAuth.currentUser.uid;
    } else {
      try {
        const decoded = jwtDecode<{ user_id: string }>(
          window.localStorage.getItem('idToken') ?? '',
        );
        return decoded.user_id;
      } catch {
        return null;
      }
    }
  }

  async signin(email: string, password: string): Promise<SignInResult> {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: `Attempting to signInWithEmailAndPassword user ${email}`,
      level: 'info',
    });

    const urlParams = new URLSearchParams(window.location.search);
    const actingOrganizationId = urlParams.get('actingOrganizationId');

    const finaliseSignIn = async (user: User, actingOrganizationId: string | null) => {
      // It is vital that we immediately cache the idToken so that when `api.didLogin()` (below)
      // calls `auth.getIdToken()` it won't fail.
      window.localStorage.setItem('idToken', user.accessToken);

      if (actingOrganizationId) {
        this.setActingOrganizationId(actingOrganizationId);
      } else {
        // Similarly, ensure the actingOrganizationId is valid so `api.didLogin` does not fail
        await this.ensureValidActingOrganizationId(user.uid);
      }

      posthog.capture('login');
      await api.didLogin();
    };

    const prepareMfaSignIn = async (error: MultiFactorError): Promise<MfaRequiredSignInResult> => {
      if (error.code !== 'auth/multi-factor-auth-required') {
        throw error;
      }

      const mfaResolver = getMultiFactorResolver(getAuth(), error);

      const totpAuthInfo = _.find(
        mfaResolver.hints,
        (info) => info.factorId == TotpMultiFactorGenerator.FACTOR_ID,
      );

      // Only supporting TOTP MFA for now so rethrow the error
      // if we can't find a TOTP auth info
      if (!isDefined(totpAuthInfo)) {
        throw error;
      }

      return {
        additionalLoginSteps: AdditionalLoginSteps.OneTimePassword,
        finaliseSignIn: async (oneTimePassword: string) => {
          try {
            const assertion = TotpMultiFactorGenerator.assertionForSignIn(
              totpAuthInfo.uid,
              oneTimePassword,
            );

            const user = (await mfaResolver.resolveSignIn(assertion)).user;
            await finaliseSignIn(user, actingOrganizationId);
            return 'Success';
          } catch (e) {
            if (isMultiFactorError(e) && e.code === 'auth/totp-challenge-timeout') {
              return 'Timeout';
            } else {
              throw e;
            }
          }
        },
      };
    };

    try {
      const user = (await signInWithEmailAndPassword(this.firebaseAuth, email, password)).user;
      return {
        additionalLoginSteps: AdditionalLoginSteps.None,
        finaliseSignIn: async () => await finaliseSignIn(user, actingOrganizationId),
      };
    } catch (error: unknown) {
      if (isMultiFactorError(error)) {
        return await prepareMfaSignIn(error);
      } else {
        throw error;
      }
    }
  }

  async reset(email: string) {
    // see: https://firebase.google.com/docs/auth/web/manage-users#send_a_password_reset_email
    return await sendPasswordResetEmail(this.firebaseAuth, email);
  }

  async refreshMeData() {
    try {
      const response = await api.getMe();

      const oldValue = window.localStorage.getItem('me');
      const newValue = JSON.stringify(response.data.me);

      window.localStorage.setItem('me', newValue);

      /**
       * Trigger a storage event manually otherwise the current tab won't receive the event.
       * This is important because the useAuthMe hook will only update if it receives the event.
       */
      const event = new StorageEvent('storage', {
        key: 'me',
        oldValue,
        newValue,
      });
      window.dispatchEvent(event);

      // It would make the most sense to have this inside the onAuthStateChanged in Auth.constructor
      // but unfortunately we need other properties from the Feebris database which aren't available
      // until the "me" data is returned.
      Intercom.update(response.data.me);
      posthog.identify(response.data.me.id, { email: response.data.me.email });
    } catch (err) {
      Sentry.captureException(err);
      this.signout();
    }
  }

  async StartMfaSetup(): Promise<MfaSetup | MfaSetupError> {
    const user = this.firebaseAuth.currentUser;

    if (!isDefined(user)) {
      throw new Error('Must be signed in to setup MFA');
    }

    try {
      // Generate a TOTP secret.
      const multiFactorSession = await multiFactor(user).getSession();
      const secret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);

      const issuer =
        process.env.NODE_ENV === 'production' ? 'Feebris' : `Feebris-${process.env.NODE_ENV}`;

      // We don't expect to hit this since all our users will have email addresses
      // but since Firebase offers the possibility, we want handle this.
      if (!isDefined(user.email)) {
        throw new Error('User must have an email address');
      }

      const qrCodeUrl = secret.generateQrCodeUrl(user.email, issuer);

      const finaliseEnrollment = async (
        secret: TotpSecret,
        verificationCode: string,
      ): Promise<FinaliseEnrolmentResult> => {
        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
          secret,
          verificationCode,
        );

        try {
          await multiFactor(user).enroll(multiFactorAssertion, issuer);
          return 'Success';
        } catch (error) {
          if (isMultiFactorError(error) && error.code == 'auth/invalid-verification-code') {
            return 'InvalidVerificationCode';
          }
          throw error;
        }
      };

      return {
        secretKey: secret.secretKey,
        qrCodeUrl: qrCodeUrl,
        finaliseEnrollment: async (verificationCode: string) => {
          return finaliseEnrollment(secret, verificationCode);
        },
      };
    } catch (error) {
      if (isMultiFactorError(error) && error.code == 'auth/requires-recent-login') {
        return 'RequiresReauthentication';
      }
      throw error;
    }
  }

  async Reauthenticate(password: string): Promise<'Success' | 'IncorrectPassword'> {
    const user = this.firebaseAuth.currentUser;

    if (!isDefined(user) || !isDefined(user.email)) {
      throw new Error('Must be signed in to reauthenticate');
    }

    const credential = EmailAuthProvider.credential(user.email, password);

    try {
      await reauthenticateWithCredential(user, credential);
      return 'Success';
    } catch (e) {
      if (isFirebaseError(e) && e.code === 'auth/invalid-login-credentials') {
        return 'IncorrectPassword';
      }
      throw e;
    }
  }

  async loginAs(actingOrganizationId: string, userId: string) {
    const response = await api.loginAs({ userId });
    if (response.errors) {
      throw new Error(`Error during login-as: ${response.errors[0].message}`);
    }
    // Extract the two custom firebase tokens sent from the backend
    const { loginAsToken, exitLoginAsToken } = response.data.loginAs;
    // Preserve the exit token in local storage, we will need this to return back to our original
    // user.
    window.localStorage.setItem('exitLoginAsToken', exitLoginAsToken);
    // Sign in as the other user using the loginAsToken
    const { user } = await signInWithCustomToken(this.firebaseAuth, loginAsToken);
    // Set the actingOrganizationId for the user we are masquerading as. This ensures we jump
    // straight into that org. Note there is no need for an "exit" value for our current
    // actingOrganizationId because actingOrganizationIdKey's include the firebase user ID.
    // When we exit loginAs it will pick up the key for the Feebroid user naturally.
    window.localStorage.setItem(this.actingOrganizationIdKey(user.uid), actingOrganizationId);
    // Ensure we forcibly set the idToken cache so that we can immediately make api calls.
    // Grr, that firebase async initialisation strikes again!
    window.localStorage.setItem('idToken', user.accessToken);
    // NOTE: The Firebase onAuthStateChanged hook we've registered in the constructor will execute
    //       shortly after this because we have used signInWithCustomToken, above. That hook will
    //       invoke a this.refreshMeData for us.
  }

  async exitLoginAs() {
    const exitLoginAsToken = window.localStorage.getItem('exitLoginAsToken');
    if (!exitLoginAsToken) {
      return await this.signout();
    }
    const { user } = await signInWithCustomToken(this.firebaseAuth, exitLoginAsToken);
    // Remove the exit token and set the new idToken
    window.localStorage.removeItem('exitLoginAsToken');
    window.localStorage.setItem('idToken', user.accessToken);
    // NOTE: The Firebase onAuthStateChanged hook we've registered in the constructor will execute
    //       shortly after this because we have used signInWithCustomToken, above. That hook will
    //       invoke a this.refreshMeData for us.
  }

  isLoginAs() {
    return Boolean(window.localStorage.getItem('exitLoginAsToken'));
  }

  isShareTokenUser() {
    return Boolean(this.getShareToken());
  }

  isLoggedIn() {
    return this.isShareTokenUser() || window.localStorage.getItem('idToken');
  }

  shouldHideAppBar() {
    return window.localStorage.getItem('hideAppBar') === 'true';
  }

  /**
   * Ensure we have a valid actingOrganizationId. This handles the case where no
   * actingOrganizationId is set or it is now invalid.
   */
  async ensureValidActingOrganizationId(firebaseUid: string) {
    const actingOrganizationId = this.getActingOrganizationId(firebaseUid);
    const response = await api.getActingOrganizations();
    if (response.length > 0) {
      const organizationIds = response.map((o: { id: string }) => o.id) as string[];
      if (!actingOrganizationId || !organizationIds.includes(actingOrganizationId)) {
        // We know for sure that there's at least one response but the type system can't figure that out
        // and complains that mostRecentlyAddedOrg might be undefined so we're casting it to string.
        const mostRecentlyAddedOrg = _.last(organizationIds) as string;
        window.localStorage.setItem(
          this.actingOrganizationIdKey(firebaseUid),
          mostRecentlyAddedOrg,
        );
      }
    }
  }

  actingOrganizationIdKey(firebaseUid: string) {
    return `actingOrganizationId-${firebaseUid}`;
  }

  setActingOrganizationId(actingOrganizationId: string) {
    if (this.firebaseAuth.currentUser) {
      Sentry.addBreadcrumb({
        category: 'auth',
        message: `Setting actingOrganizationId to ${actingOrganizationId}`,
        level: 'info',
      });
      Sentry.setTag('actingOrganizationId', actingOrganizationId);

      window.localStorage.setItem(
        this.actingOrganizationIdKey(this.firebaseAuth.currentUser.uid),
        actingOrganizationId,
      );
    } else {
      throw new Error('Cannot set actingOrganization, Firebase user not logged in');
    }
  }

  getActingOrganizationId(firebaseUid?: string): string | null {
    return window.localStorage.getItem(
      this.actingOrganizationIdKey(firebaseUid ?? this.getUid() ?? ''),
    );
  }

  /**
   * "me" is a cached copy of the Feebris User object from the database.
   *
   * We don't return the entire 'me' object but instead force the caller to specify an object
   * path, for `_.get()` to ensure the app doesn't crash during code deployment/release. A crash
   * could happen if we change the structure of the 'me' data and the user does a browser refresh.
   * In this case the user could have old format 'me' data but be running new code (which assumes
   * a new 'me' structure). By using _.get() we can fallback to a default value until sync is
   * regained.
   */
  me(path: string, defaultValue?: unknown) {
    if (!path) {
      throw new Error('auth.me, object path argument is required');
    }
    const storageMe = window.localStorage.getItem('me');
    const me = storageMe ? JSON.parse(storageMe) : undefined;
    return _.get(me, path, defaultValue);
  }
}

const auth = new Auth(FirebaseApp.getInstance());
export default auth;
