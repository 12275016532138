import React, { useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import FileUpload from '@mui/icons-material/FileUpload';
import {
  EhrIntegrationType,
  ManualIntegrationForDataPushFragment,
  PatientDetailsFragment,
  useCreateIntegrationEventMutation,
  useGetManualIntegrationsLazyQuery,
} from '@/generated/graphql';
import { gql } from '@apollo/client';
import { useModal } from 'mui-modal-provider';
import { muiFormikGetFieldProps } from '@/helpers/formik';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMeActingOrganizationFeature, useMePermissions } from '@/hooks/useAuth';
import { toast } from 'sonner';
import { addMinutes } from 'date-fns';

interface ManualDataPushButtonProps {
  patient: Pick<PatientDetailsFragment, 'id' | 'wardAdmission'>;
  defaultCarePathwayId: Maybe<string>;
}

export const MANUAL_INTEGRATIONS_QUERY = gql`
  fragment ManualIntegrationForDataPush on EhrIntegration {
    id
    name
    integrationType
    authorizedAutomaticApprover {
      id
    }
  }

  query GetManualIntegrations($carePathwayId: ID!) {
    ehrIntegrations(carePathwayId: $carePathwayId, triggerType: Manual) {
      ...ManualIntegrationForDataPush
    }
    me {
      isQuicksilvaIdentitySetup
    }
  }
`;

export const CREATE_INTEGRATION_EVENT_MUTATION = gql`
  mutation CreateIntegrationEvent($input: CreateManualEhrIntegrationEventInput!) {
    createManualEhrIntegrationEvent(manualEhrIntegrationEventInput: $input)
  }
`;

export const ManualDataPushButton = ({
  patient,
  defaultCarePathwayId,
}: ManualDataPushButtonProps) => {
  const { showManualDataPushModal } = useManualDataPushModal();

  const carePathwayId = patient.wardAdmission?.carePathway?.id ?? defaultCarePathwayId;

  const permissions = useMePermissions();

  // We have to use a lazy query here since the care pathway ID may not be defined
  // and we only want to conditionally run the query when it is defined (see the useEffect).
  const [getManualIntegrations, { data, loading }] = useGetManualIntegrationsLazyQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { carePathwayId: carePathwayId! },
  });

  useEffect(() => {
    const getManualIntegrationsQuery = async () => {
      if (carePathwayId) {
        await getManualIntegrations();
      }
    };

    getManualIntegrationsQuery();
  }, [carePathwayId, getManualIntegrations]);

  const integrations = data?.ehrIntegrations || [];
  const isQuicksilvaIdentitySetup = data?.me?.isQuicksilvaIdentitySetup ?? false;
  const tooltipTitle =
    integrations.length <= 0 ? 'No manual integrations configured for pathway' : '';
  const integrationsFeatureEnabled = useMeActingOrganizationFeature('ehrIntegrations');

  return integrationsFeatureEnabled && permissions['create_ehr_integration_events'] ? (
    <Tooltip title={tooltipTitle}>
      {/* Tooltips don't work on disabled buttons. MUI recommend wrapping it in a span in this case.
          https://mui.com/material-ui/react-tooltip/#disabled-elements */}
      <span>
        <Button
          startIcon={<FileUpload />}
          size="small"
          variant="outlined"
          color="primary"
          disabled={loading || integrations.length === 0}
          onClick={async () => {
            if (integrations.length > 0) {
              showManualDataPushModal({
                patientId: patient.id,
                integrations,
                isQuicksilvaIdentitySetup,
              });
            }
          }}>
          Send to Clinical Record
        </Button>
      </span>
    </Tooltip>
  ) : (
    <></>
  );
};

const useManualDataPushModal = () => {
  const { showModal } = useModal();
  const [createIntegrationEvent] = useCreateIntegrationEventMutation({
    onError: () => toast.error('An error occurred when attempting to send the data'),
    onCompleted: () =>
      toast.success('Sending data. Progress can be checked in the integration queue'),
  });

  return {
    showManualDataPushModal: ({
      patientId,
      integrations,
      isQuicksilvaIdentitySetup,
    }: {
      patientId: string;
      integrations: ManualIntegrationForDataPushFragment[];
      isQuicksilvaIdentitySetup: boolean;
    }) => {
      const modal = showModal(ManualDataPushModal, {
        integrations,
        isQuicksilvaIdentitySetup,
        onCancel: () => {
          modal.hide();
        },
        onSend: async (values) => {
          await createIntegrationEvent({
            variables: {
              input: {
                patientId,
                ehrIntegrationId: values.integration,
                from: values.startDate.toISOString(),
                to: values.endDate.toISOString(),
              },
            },
          });
          modal.hide();
        },
      });
    },
  };
};

interface ManualDataPushInput {
  integration: string;
  startDate: Date;
  endDate: Date;
}

const manualDataPushSchema = Yup.object<ManualDataPushInput>().shape({
  integration: Yup.string().required('Integration is required'),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date()
    .required('End date is required')
    .test('is-after-start-date', 'End date must be after start date', function (value) {
      return this.parent.startDate < value;
    })
    // add one minute past the current date to allow the user to select 'now' as the end date because 'max' is non-inclusive
    .max(addMinutes(new Date(), 1).toISOString(), 'End date cannot be in the future'),
});

interface ManualDataPushModalProps extends DialogProps {
  integrations: ManualIntegrationForDataPushFragment[];
  isQuicksilvaIdentitySetup: boolean;
  onSend: (values: ManualDataPushInput) => Promise<void>;
  onCancel: () => void;
}

const ManualDataPushModal = ({
  integrations,
  isQuicksilvaIdentitySetup,
  open,
  onSend,
  onCancel,
}: ManualDataPushModalProps) => {
  const formik = useFormik({
    initialValues: {
      integration: integrations[0].id,
      startDate: '',
      endDate: '',
    },
    validationSchema: manualDataPushSchema,
    onSubmit: async (values) => {
      setSubmitting(true);

      try {
        await onSend({
          startDate: new Date(values.startDate),
          endDate: new Date(values.endDate),
          integration: values.integration,
        });
      } catch (e) {
        toast.error('An error occurred when attempting to send the data');
      }

      setSubmitting(false);
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const [submitting, setSubmitting] = React.useState(false);
  const getFieldProps = muiFormikGetFieldProps(formik);
  const getSelectedIntegration = () => integrations.find((x) => x.id === formik.values.integration);

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>Send Data to Clinical Record</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box display="flex" flexDirection="column" marginTop={1}>
            <DialogContentText>
              Send aggregated intermittent and continuous monitoring vitals, including notes, from
              between selected dates to chosen integration. If the chosen date range contains no
              data, the data push will be skipped and nothing will be sent to the clinical record.
            </DialogContentText>

            <Box marginTop={3}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label="Integration"
                placeholder="Select integration"
                select
                {...getFieldProps('integration')}>
                {integrations.map((integration) => (
                  <MenuItem
                    key={integration.id}
                    value={integration.id}
                    aria-label={integration.name}>
                    <Stack>
                      <Typography textTransform="capitalize" variant="body1">
                        {integration.name}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <Box marginTop={2} display="flex" justifyContent="space-between" gap={3}>
              <TextField
                type="datetime-local"
                label="Start Date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                {...getFieldProps('startDate')}
              />
              <TextField
                type="datetime-local"
                label="End Date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                {...getFieldProps('endDate')}
              />
            </Box>

            <ApproverDisclaimer
              getSelectedIntegration={getSelectedIntegration}
              isQuicksilvaIdentitySetup={isQuicksilvaIdentitySetup}
              marginTop={3}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={submitting}>
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained" disabled={submitting}>
            Send to Clinical Record
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ApproverDisclaimer = ({
  getSelectedIntegration,
  marginTop,
  isQuicksilvaIdentitySetup,
}: {
  getSelectedIntegration: () => ManualIntegrationForDataPushFragment | undefined;
  marginTop: number;
  isQuicksilvaIdentitySetup: boolean;
}) => {
  const selectedIntegration = getSelectedIntegration();
  if (!selectedIntegration) {
    // this shouldn't happen - the disclaimer should only be shown for a selected integration
    return <></>;
  }

  const isQuicksilvaIntegration =
    selectedIntegration.integrationType === EhrIntegrationType.QuicksilvaMessage;

  const authorisedApproverOnIntegration = selectedIntegration.authorizedAutomaticApprover;

  if (isQuicksilvaIntegration) {
    if (isQuicksilvaIdentitySetup) {
      return (
        <Box marginTop={marginTop}>
          <Alert severity="info">
            Since you have linked your Quicksilva toolbar, the data will be marked as automatically
            approved by you.
          </Alert>
        </Box>
      );
    }

    if (authorisedApproverOnIntegration) {
      return (
        <Box marginTop={marginTop}>
          <Alert severity="info">
            The data will be marked as automatically approved by the approver setup for this
            integration. If you would prefer the data to be marked as approved by you, please link
            your Quicksilva toolbar.
          </Alert>
        </Box>
      );
    }

    return (
      <Box marginTop={marginTop}>
        <Alert severity="warning">
          No automatic approvals are setup for this integration. The data will need to be approved
          manually in the Quicksilva toolbar once sent.
        </Alert>
      </Box>
    );
  }

  return <></>;
};
